@import '~#/theme/main';

.button {
  backdrop-filter: blur(67.957px);
  background: rgba(255, 255, 255, .2);
  border: none;
  border-radius: vw(3px);
  color: var(--coloronbackground);
  font-size: vw(24px);
  font-weight: 400;
  height: vw(69px);

  @include browser($legacy-devices) {
    /* stylelint-disable */
    border-width: 1px;
    border-style: solid;
    border-color: $button-border-grey;
    /* stylelint-enable */
    border-radius: 2px !important;
  }

  &.selected {
    background-color: $lightgrey;
  }

  &.focused {
    background-color: $blue;
    color: var(--coloronaccent);
    transition: .5s;
  }

  & span {
    align-items: center;
    display: flex !important;
    justify-content: center;
    padding: vw(15px) vw(20px);
  }
}
