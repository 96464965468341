.container {
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;

  &.hide {
    display: none;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &::after {
    background:
      linear-gradient(254deg, rgba(0, 0, 0, 0) 18.9%, #000 100%),
      linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0) 17.48%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
