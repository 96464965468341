$rgb-0: rgb(0, 0, 0);
$rgb-10: rgb(10, 10, 10);
$rgb-30: rgb(30, 30, 30);
$rgb-40: rgb(40, 40, 40);
$rgb-50: rgb(50, 50, 50);
$rgb-60: rgb(60, 60, 60);
$rgb-100: rgb(100, 100, 100);
$rgb-150: rgb(150, 150, 150);
$rgb-153: rgb(153, 153, 153);
$rgb-160: rgb(160, 160, 160);
$rgb-200: rgb(200, 200, 200);
$rgb-230: rgb(230, 230, 230);
$rgb-235: rgb(235, 235, 235);
$rgb-240: rgb(240, 240, 240);
$rgb-250: rgb(250, 250, 250);
$rgb-255: rgb(255, 255, 255);
$black-90: rgba(0, 0, 0, .9);
$black-80: rgba(0, 0, 0, .8);
$black-70: rgba(0, 0, 0, .7);
$black-60: rgba(0, 0, 0, .6);
$black-50: rgba(0, 0, 0, .5);
$black-40: rgba(0, 0, 0, .4);
$black-30: rgba(0, 0, 0, .3);
$black-20: rgba(0, 0, 0, .2);
$black-10: rgba(0, 0, 0, .1);
$black-0: rgba(0, 0, 0, 0);
$aquamarine: rgb(127, 255, 212);
$transparent: rgba(0, 0, 0, 0);
$grey-95: rgba(100, 100, 100, .95);
$grey-55: rgba(91, 91, 91, .55);
$dark-grey-80: rgba(40, 40, 40, .8);
$light-grey: $rgb-240;
$lightgrey: lightgrey;
$dark-grey: $rgb-60;
$grey: grey;
$button-border-grey: #f2f3f4;
$black: $rgb-0;
$main-highlight-color: $aquamarine;
$dark: #404040;
$red: red;
$white: $rgb-255;
$highlight: #48b5e9;
$heading-color: $highlight;
$main-text-color: rgb(240, 248, 255);
$metadata-blue: #3bbfff;
$metadata-color: rgba(255, 255, 255, .6);
$season-selector-bg: rgba(255, 255, 255, .2);
$blue: #0064ff;

// Reference app specific
$highlight-blue: #3dc2ff;
$background-color: rgb(30, 30, 30);
$font-color: $white;
$link-color: $rgb-160;
