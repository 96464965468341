@import '~#/theme/vw';

@import '~#/theme/colors';

.container {
  display: block;
  height: vw(50px);
  padding-top: vw(150px);
}

// filter
.wrapper {
  display: inline-block;
  margin-right: vw(50px);
}

.button-wrapper {
  height: auto;
}

.button {
  height: auto;
}

.list-container {
  align-items: center;
  background-color: $rgb-30;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1;

  .button {
    margin: .5em;

    button {
      width: vw(300px);
    }
  }
}

.is-hidden {
  bottom: 9999px;
  display: none;
  left: -9999px;
  right: 9999px;
  top: -9999px;
  z-index: 0;
}

.arrow-icon {
  height: vw(17px);
}
