@import '~#/theme/main';

@import '~#/theme/colors';

$details-text-color: $rgb-235;

.button {
  button {
    background-color: $rgb-30;
    border: 0;
    border-radius: vw(16px);
    color: var(--coloronbackground);
    font-size: vw(25px);
    font-weight: 400;
    height: vw(88px);
    width: vw(475px);
  }

  &.selected {
    background-color: rgba(241, 242, 243, .2);
  }

  &.focused {
    background-color: var(--accent);
    color: var(--coloronaccent);
  }
}

.icon {
  height: vw(45px);
  position: absolute;
  right: vw(20px);
  top: vw(34px);
  width: vw(55px);
}
