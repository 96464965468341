@import '~#/theme/main';

$details-text-color: $rgb-235;
$portrait-item-border: vw(6px) solid #0064ff;
$portrait-item-box-shadow: 0 0 30px 10px rgba(0, 100, 255, .7);

.container {
  box-sizing: border-box;
  display: inline-block;
  font-family: Outfit, sans-serif;

  .live-badge {
    left: 20px;
    position: absolute;
    top: 20px;
    width: vw(100px);
  }

  .rating {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: vw(20px);

    .star-icon {
      height: vw(29px);
      margin-right: vw(8px);
      width: vw(29px);
    }
  }

  .link {
    height: 85%;
    position: relative;
    width: 100%;
  }

  .background {
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: vw(6px) solid transparent;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;

    @include browser($legacy-devices) {
      border-radius: 6px !important;
    }

    &.background-focused {
      border: .3125vw solid $blue;
      border-radius: 6px;
      box-shadow: 0 0 30px 10px rgba(0, 100, 255, .7);
    }

    &.background-focused .click-area {
      position: absolute;
      top: 0;
    }

    .view-all {
      font-size: 26px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 35%;
      width: 100%;
    }

    &.view-all-background {
      background-color: #303030;
      border-radius: vw(19px);
    }

    .logo-image {
      object-fit: contain;
    }
  }

  .movie-link {
    height: 100%;
    position: relative;
    width: 100%;

    > div {
      height: 97%;

      &.background-focused {
        border: $portrait-item-border;
        box-shadow: $portrait-item-box-shadow;
      }
    }
  }

  .search-link {
    height: 100%;
    margin: vw(40px) vw(46px);
    position: relative;
    width: 100%;

    > div {
      height: 100%;

      &.background-focused {
        border: $portrait-item-border;
        box-shadow: $portrait-item-box-shadow;
      }
    }
  }
}

.kid-profile {
  border-radius: vw(40px) !important;
}

.content-container {
  align-items: start;
  box-sizing: border-box;
  color: var(--coloronbackground);
  display: flex;
  font-family: Outfit, sans-serif;
  font-size: vw(27px);
  font-weight: 400;
  height: vw(65px);
  left: vw(2px);
  min-height: vw(32px);
  padding: vw(5px);
  padding-top: .7vh;

  .title-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.title-continue-watching {
        padding: vw(24px) 0 0 0;
      }
    }

    .subtitle {
      color: gray;
    }
  }

  .watch-time {
    color: $metadata-color;
    min-width: vw(220px);
    padding: 0 0 0 vw(24px);
    white-space: nowrap;
  }
}

.content-container-focused {

  @extend .content-container;
}
