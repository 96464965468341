@import '~#/components/Item/AssetItem/assetItem';

@import '~#/theme/vw';

.background {
  background-position: center;

  &.content-container {
    .title {
      color: rgb(53, 159, 189);
    }

    .genres {
      font-size: vw(25px);
    }
  }
}

.continue-watching-item {
  backdrop-filter: blur(67.957px);
  position: relative;

  .progress {
    background: rgba(255, 255, 255, .2);
    border-radius: 35px;
    bottom: vw(24px);
    box-shadow: 2px 4px 5px 0 rgba(0, 0, 0, .4);
    height: vw(12px);
    position: absolute;
    width: 94%;

    > span {
      background-color: $blue;
      border-radius: 35px;
      display: block;
      height: 100%;
    }
  }
}
