@import '~#/theme/main';

$navbar-colors: (
  #0065ff,
  #5452cb,
  #9045a6,
  #e73271,
  #ff4755,
  #ff5b4c,
  #fe7340,
  #ff9531,
  #ffae25
);

.item {
  button {

    @include default-button;

    @include subtitle-font-size;

    align-items: center;
    background: transparent;
    border: 0;
    border-radius: 0;
    color: rgba(242, 243, 244, .8);
    cursor: pointer;
    display: flex;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  img {
    border-radius: 50%;
    object-fit: cover;
  }
}

.primary {
  button {
    padding: vw(20px) vw(24px);
  }
}

.secondary {
  button {
    padding: vw(14.5px) vw(24px);
  }
}

.icon {
  height: fit-content;

  svg {
    height: vw(45px);

    path {
      fill: var(--coloronbackground);
    }
  }
}

.olympics {
  align-items: center;
  border-radius: vw(35px);
  color: var(--coloronaccent);
  display: flex;

  button {
    background: none;
    padding: vw(20px) 0;
  }

  svg {
    margin: 0 vw(20px);
    width: 1.5rem;
  }
}

.selected {
  .olympics {
    background: #0133ff;
  }

  .default {
    button {
      border-radius: vw(35px);
      color: #ffff;
    }
  }
}
// stylelint-disable scss/no-global-function-names
.focused {
  .olympics {
    background: #0133ff;
  }

  &.selected {
    .olympics {
      background: #0933db;
    }
  }

  /* stylelint-disable-next-line at-rule-empty-line-before */
  @for $i from 1 to length($navbar-colors) {
    .default.color_#{$i} {
      button {
        background:
          linear-gradient(
            90deg,
            nth($navbar-colors, $i) 0%,
            nth($navbar-colors, $i + 1) 54%
          );
        border-radius: vw(35px);
        color: var(--coloronaccent);
      }
    }
    .icon.color_#{$i} {
      button {
        background-color: nth($navbar-colors, $i);
        border-radius: vw(35px);
        color: var(--coloronaccent);
      }
    }
  }
}
