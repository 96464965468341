@import '~#/theme/main';

$description-bg-color: rgba($rgb-235, .15);

.episode {
  border: vw(3px) solid transparent;
  border-radius: 3px;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include browser($legacy-devices) {
    border-radius: 3px !important;
    width: vw(570px);
  }

  img {
    border: vw(3px) solid transparent;
  }

  &.focused {
    border: vw(3px) solid $blue;
    box-shadow: -1px -2px 31px 2px rgba(0, 100, 255, .51);
  }

  .container-image {
    height: 48%;
    padding-top: calc(3 / 16 * 100%);
    position: relative;
    width: 100%;
  }

  .cover-image {
    display: block;
    height: 100%;
    left: 0;
    margin: -2px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  > p,
  .progress {
    position: absolute;
  }

  p {
    bottom: vw(20px);
    color: $light-grey;
    font-size: vw(20px);
    margin: 0;
    padding: 0 vw(10px);
  }

  .progress {
    background-color: rgba($light-grey, .5);
    border-top: vw(1px) solid $grey;
    bottom: vw(69px);
    height: vw(5px);
    left: 0;
    width: 100%;
    z-index: 1;

    > span {
      background-color: $button-border-grey;
      display: block;
      height: 100%;
    }
  }

  .container-metadata {
    backdrop-filter: blur(65px);
    background: rgba(255, 255, 255, .2);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .4);
    font-family: Outfit, sans-serif;
    font-size: vw(22px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: vw(-8px);
    min-height: vw(500px);
    overflow: hidden;
    padding: 0 vw(24px) 0 vw(24px);
    width: 100%;
  }

  .title-container {
    align-items: baseline;
    bottom: vw(5.5px);
    color: $rgb-235;
    display: flex;
    font-size: vw(25px);
    justify-content: space-between;
    line-height: vw(32px);
    padding: vw(19.2px) 0 vw(5px);
    position: relative;
    width: 90%;

    @include browser($legacy-devices) {
      width: vw(531px);
    }
  }

  .title {
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .duration {
    color: rgba(255, 255, 255, .6);
  }

  .description {
    color: rgba(255, 255, 255, .6);
    font-family: Outfit, sans-serif;
    font-size: vw(22px);
    font-weight: 400;
    line-height: 1.1;
    max-height: 2.5vw;
    overflow: hidden;
    position: relative;
    width: 95%;

    &::before {
      bottom: 0;
      content: '...';
      position: absolute;
      right: 0;
    }
  }

  .watch-time {
    font-size: vw(22px);
  }
}
