@import '~#/theme/vw';

.logo {
  height: vw(43px);
  position: relative;
  width: vw(200px);
}

.kids-logo {
  height: vw(80px) !important;
  width: vw(277px) !important;
}
