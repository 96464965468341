@import '~#/theme/colors';

@import '~#/theme/main';

@import '~#/theme/vw';

$popup-text-color: $rgb-235;
$popup-focused-text-color: $rgb-255;

.container {
  background-color: rgba(0, 0, 0, .4);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.inner {
  background-color: var(--alternativebackground);
  height: 40vh;
  left: 50%;
  padding: 5%;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;

  @include browser($legacy-devices) {
    left: 15% !important;
    top: 20% !important;
  }
}

.title {
  color: $popup-text-color;
  font-size: vw(51px);
  height: vw(134px);
  line-height: 2.5;
}

.content {
  color: var(--coloronbackground);
  font-size: vw(42px);
  height: vw(110px);
  line-height: normal;
  margin-bottom: 10%;
  opacity: .8;
}

/* Modal Button */
.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.focus-button-container {
  border: 0;
  margin-right: vw(18px);
}

.focus-button {
  background: var(--background);
  border: 0;
  border-radius: vw(100px);
  color: var(--coloronbackground);
  cursor: pointer;
  font-size: vw(30px);
  overflow: hidden;
  padding: 2vh;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10vw;

  :focus {
    background: var(--accent);
    outline: 0;
  }

  :hover {
    background: var(--accent);
  }
}

.focus-button-focused {
  background: var(--accent);
}

.focus-button-active {
  color: #0a6ebd;
}
