@import '~#/theme/main';

.kids-wrapper {
  background-size: auto;

  &.kids-category-focused {
    .glow {
      box-shadow: 0 0 70px 60px rgba(0, 100, 255, .7);
      height: 210px;
      left: 22%;
      position: absolute;
      top: 22%;
      width: 210px;
      z-index: -1;
    }
  }

  .kids-title {
    color: var(--coloronbackground);
    font-size: vw(30px);
    font-weight: 400;
    position: relative;
    text-align: center;
    top: 95%;
    transform: translateY(-50%);
  }
}

.wrapper {
  background-color: $rgb-60;
  border-radius: vw(3px);
  box-sizing: border-box;

  &.focused {
    background-color: var(--accent);
  }

  .title {
    color: var(--coloronbackground);
    font-size: vw(30px);
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
}
