@import '~#/theme/vw';

@import '~#/theme/colors';

.title {
  color: $font-color;
  font-size: vw(48px);
  font-style: normal;
  font-weight: 400;
  position: absolute;
  text-align: center;
  top: vw(230px);
  width: 100%;
}

.container {
  background-color: $rgb-30;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;

  .center {
    margin: auto;
    position: relative;
    text-align: center;
    width: vw(475px);
  }
}
