@import '~#/theme/vw';

.arrow-button {
  background: var(--alternativebackground);
  border: 1px solid var(--coloronbackground);
  border-radius: vw(16px);
  color: var(--coloronbackground);
  height: vw(66px);
  left: vw(90px);
  position: absolute;
  right: auto;
  width: vw(100px);

  svg {
    position: relative;
    top: vw(14px);
    width: 60px;
  }
}

.arrow-button-focused {
  background: var(--accent);
  border: 2px solid var(--accent);
  color: var(--coloronaccent);
}

.prev-button {
  &.button {

    @extend .arrow-button;

    left: vw(90px);
    right: auto;
  }

  &.button-focused {

    @extend .arrow-button-focused;
  }

  &.prev-button-horizontal {
    left: vw(50px);
    right: auto;
    text-align: center;
    top: 40%;
  }

  &.prev-button-vertical {
    left: 45%;
    top: vw(50px);

    svg {
      left: vw(24px);
      top: vw(17px);
      width: 50px;
    }
  }
}

.next-button {
  &.button {

    @extend .arrow-button;
  }

  &.button-focused {

    @extend .arrow-button-focused;
  }

  &.next-button-horizontal {
    left: auto;
    right: vw(50px);
    text-align: center;
    top: 40%;
  }

  &.next-button-vertical {
    bottom: vw(50px);
    left: 45%;

    svg {
      left: vw(24px);
      top: vw(17px);
      width: 50px;
    }
  }
}
