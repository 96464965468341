@import '~#/theme/main';

.background {

  @include bottom-align-container;

  background-repeat: no-repeat;
  background-size: cover;
  height: inherit;
  overflow: hidden;

  img {
    min-width: 100vw;
    object-fit: cover;
  }
}

.content-container {

  @include bottom-align-item;

  background:
    linear-gradient(267deg, rgba(0, 0, 0, 0) 40.31%, #000 96.05%),
    linear-gradient(1deg, #000 .52%, rgba(0, 0, 0, .1) 28.73%, rgba(0, 0, 0, 0) 40.4%);
  box-sizing: border-box;
  height: 100%;
  padding: vw(270px) vw(88px) vw(77px);
  width: 100%;

  .button {
    font-size: vw(30px);
    height: vw(35px);
    left: 5vw;
    line-height: normal;
    top: 20vh;
  }
}

.title {
  color: $font-color;
  font-size: vw(50px);
  height: vw(79px);
  line-height: normal;
  margin-top: vw(50px);
  padding-bottom: vw(12px);
  padding-top: vw(24px);
  text-shadow: $text-shadow;
}
