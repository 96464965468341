@import '~#/theme/main';

.avatar {
  border: vw(4px) solid transparent;
  border-radius: 50%;
  height: vw(41px);
  width: vw(42px);

  img {
    height: vw(41px);
    object-fit: cover;
    width: vw(42px);
  }
}

.name {
  align-items: center;
  background-color: rgba(242, 243, 244, .2);
  color: rgba(242, 243, 244, .8);
  display: flex;
  font-size: vw(36px);
  font-style: normal;
  font-weight: 500;
  justify-content: center;
}

.focused {
  animation: highlight .3s forwards;
  border: vw(4px) solid #ff5b4c;
}
